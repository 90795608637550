/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";

import { Row, Table } from "react-bootstrap";

import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Prices.scss";

const Prices = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, year, prices, intervals, extra } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="content">
        <Table responsive>
          <thead>
            <tr>
              <th>{year}</th>
              {intervals.map((i) =>
                <th key={i}>{i}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {prices.map((p) => (<tr key={p.name}>
              <td key={p.name}>{p.name}</td>
              {p.values.map((v) => (<td key={v}>{v}</td>))}
            </tr>))
            }
          </tbody>
        </Table>
      </Row>
      <Row className="extra">
        {extra}
      </Row>
    </PageSection >
  );
};

Prices.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Prices.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Prices;
