import React from "react";
import PropTypes from "prop-types";

import { Carousel } from "react-bootstrap";
import useSmoothScrollTo from "hooks/useSmoothScrollTo";
import ImageCard from "components/ImageCard";
import Image from "components/Image";
import * as SocialIcons from "components/SocialIcons";

import "./Top.scss";

const Top = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { jumpToAnchor, jumpToAnchorText, slides, logoFileName, social: { facebook } } = frontmatter;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollToSection = useSmoothScrollTo(jumpToAnchor);

  let extraInfoPart;
  if (jumpToAnchor && jumpToAnchorText) {
    extraInfoPart = (
      <div>
        <Image className="imageLogo img-responsive center-block d-block mx-auto" alt="logo" fileName={logoFileName} onClick={scrollToSection} />

        <p className="test">
          {facebook ? <SocialIcons.Facebook userName={facebook} /> : null}
          {/* {instagram ? <SocialIcons.Instagram userName={instagram} /> : null} */}
        </p>
      </div>
    );
  }

  return (
    <Carousel className="images">
      {slides.map(({ header, subheader, imageFileName }) => (
        <Carousel.Item key={imageFileName}>
          <ImageCard
            imageFileName={imageFileName}
            header={header}
            subheader={subheader}
            extraInfo={extraInfoPart}
          />
        </Carousel.Item>
      ))}

    </Carousel>

  );
};

Top.propTypes = {
  frontmatter: PropTypes.object,
};

Top.defaultProps = {
  frontmatter: null,
};

export default Top;
