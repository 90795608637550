import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Row, Tab, Nav } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import PhotoGallery from "components/PhotoGallery";

import "./Apartments.scss";

const Apartments = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, apartment1, apartment2, apartment3, apartment4 } = frontmatter;

  return (
    <PageSection className={clsx("apartments-section", className)} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <div>
        <Tab.Container defaultActiveKey="first" className="">
          <Nav variant="pills" className="nav justify-content-center">
            <Nav.Item >
              <Nav.Link className="nav-links" eventKey="first">{apartment1.description}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="nav-links" eventKey="second">{apartment2.description}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="nav-links" eventKey="third">{apartment3.description}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="nav-links" eventKey="fourth">{apartment4.description}</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="gallery-tabs">
            <Tab.Pane eventKey="first">
              <PhotoGallery section={apartment1.photos} />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <PhotoGallery section={apartment2.photos} />
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <PhotoGallery section={apartment3.photos} />
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
              <PhotoGallery section={apartment4.photos} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </PageSection >
  );
};

Apartments.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Apartments.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Apartments;
