import React from "react"
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import { ImgixGatsbyImage } from '@imgix/gatsby';

import "./Live.scss";

const Live = ({ className, frontmatter }) => {
  const { anchor, header: rootHeader, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <div className="justify-content-center">
        <Row>
          <Col>
            <ImgixGatsbyImage
              // Must be an imgix URL
              src="https://triglav.ibohinj.si/latest"
              alt="live"
              // This can be used to set imgix param

              // These are passed through to the gatsby-plugin-image component
              layout="fullWidth"

              // Set either
              aspectRatio={24 / 18}
            // or


            // Any other props offered by the gatsby-plugin-image component are
            // supported and passed straight through to the component
            />
          </Col>
        </Row>

      </div>

    </PageSection>
  );
};

Live.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Live.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Live;
